<template>
  <div>
    <v-page-title title="Integrations"></v-page-title>

    <v-card max-width="1200px" elevation="0" color="transparent" class="mx-auto my-5">
      <v-container>
        <v-row>
          <v-col>
            <v-integrations></v-integrations>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Integrations.vue',
    data() {
      return {

      }
    },
    computed:{
    },
  }
</script>

<style lang="scss" scoped>
</style>